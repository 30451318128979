@font-face {
  font-family: "Larken";
  src: url("./font_LarkenThin.woff")
    format("woff");
  font-weight: 200;
}

@font-face {
  font-family: "Larken";
  src: url("./font_LarkenLight.woff")
    format("woff");
  font-weight: 400;
}