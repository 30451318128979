@import './../base/breakpoints_base';

@mixin Typography {
  & * {
    font-family: var(--typeface), Trebuchet, 'Century Gothic', 'Segoe UI',
      sans-serif;
  }

  &{
    color: var(--text_color)
  }

  h1.remove-m,
  h2.remove-m,
  h3.remove-m,
  h4.remove-m,
  h5.remove-m,
  h6.remove-m,
  .small-text-option1,
  .small-text-option2 {
    margin: 0;
  }

  h1 {
    color: var(--heading_primary_color);
    font-family: var(--secondary_typeface), Trebuchet, 'Century Gothic', 'Segoe UI',
      sans-serif;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--heading_secondary_color);
    font-family: var(--secondary_typeface), Trebuchet, 'Century Gothic', 'Segoe UI',
      sans-serif;
  }

  h1 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 30px;
    line-height: 37px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  h6 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .small-text-option1 {
    font-size: 12px;
    font-weight: 500;
    color: var(--small_text_color_1);
  }

  .small-text-option2 {
    font-size: 12px;
    font-weight: 500;
    color: var(--small_text_color_2);
  }

  @include media-breakpoint-down(lg) {
    h1 {
      margin-bottom: 20px;
    }

    h2 {
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 30px;
    }

    h3 {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 15px;
    }

    h4 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    h5 {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
    }

    h6 {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }

  @include media-breakpoint-down(md) {
    h1 {
      font-size: 28px;
      line-height: 33px;
    }

    h2 {
      font-size: 24px;
      line-height: 30px;
    }

    h3 {
    }

    h4 {
    }

    h5 {
    }

    h6 {
    }

    .small-text-option1 {
      font-size: 10px;
      font-weight: 400;
    }

    .small-text-option2 {
      font-size: 10px;
      font-weight: 400;
    }
  }
}
