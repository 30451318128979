@mixin Scrollbar {
    * {
        scrollbar-face-color: var(--scrollbar_thumb_color);
        scrollbar-3dlight-color: var(--scrollbar_thumb_color);
        scrollbar-track-color: var(--scrollbar_track_color);
        scrollbar-arrow-color: var(--scrollbar_thumb_color);
        scrollbar-shadow-color: var(--scrollbar_thumb_color);

        /* Chrome scrollbar */
        &::-webkit-scrollbar-track {
            background-color: var(--scrollbar_track_color);
        }

        &::-webkit-scrollbar {
            width: 5px;
            background-color: var(--scrollbar_track_color);
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background-color: var(--scrollbar_thumb_color);
        }

        // Firefox scrollbar
        & {
            scrollbar-color: var(--scrollbar_thumb_color) transparent;
        }
    }
}
